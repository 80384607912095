import { createApp } from 'vue'
import { VueFire } from 'vuefire'
import { firebaseApp } from './firebase'
import { createPinia } from 'pinia'


// import { domain, clientId, audience } from '../auth_config';

import App from "./layout/index.vue";
const pinia = createPinia()

import { router } from "@/router/index.js";
import JsonExcel from "vue-json-excel3";
import CncptBtn from '@/components/elements/cncpt-components/CncptBtn'
import CncptInput from '@/components/elements/cncpt-components/CncptInput'
import CncptCardContainer from '@/components/elements/cncpt-components/CncptCardContainer'
import CncptBasicSelect from '@/components/elements/cncpt-components/CncptBasicSelect'
import CncptSelectV2 from '@/components/elements/cncpt-components/CncptSelectV2'
import CncptCardCombobox from '@/components/elements/cncpt-components/CncptCardCombobox'
import CncptDatepicker from '@/components/elements/cncpt-components/CncptDatepicker'
import CncptCardSelect from '@/components/elements/cncpt-components/CncptCardSelect'
import CncptCardInput from '@/components/elements/cncpt-components/CncptCardInput'
import CncptSliderCard from "@/components/elements/cncpt-components/CardSlider";

import CncptCardSwitch from '@/components/elements/cncpt-components/CncptCardSwitch'
import CncptSwitch from '@/components/elements/cncpt-components/CncptSwitch'
import CncptSelect from '@/components/elements/cncpt-components/CncptSelect'
import CncptSlider from '@/components/elements/cncpt-components/CncptSlider'
import CncptCombobox from '@/components/elements/cncpt-components/CncptCombobox'
import CncptTextField from '@/components/elements/cncpt-components/CncptTextField'
import CncptTextArea from '@/components/elements/cncpt-components/CncptTextArea'
// Import the Auth0 configuration
import { domain, clientId, audience } from '../auth_config';
import { createAuth0 } from '@auth0/auth0-vue';
const auth0Instance = createAuth0({
  domain,
  clientId,
  audience: audience,
  scope: 'openid profile email',
  authorizationParams: {
    redirect_uri: window.location.origin
  },
  // onRedirectCallback: appState => {
  //   router.push(
  //     appState && appState.targetUrl
  //       ? appState.targetUrl
  //       : window.location.pathname
  //   );
  // }
})
// Import the plugin here
// import { initialize } from './auth';
// initialize()







// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'

import { md3 } from 'vuetify/blueprints'


import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
const vuetify = createVuetify({
  components: {
    ...components,

  },
  directives,
  blueprint: md3,
  theme: {

    themes: {
      light: {
        dark: false,
        colors: {

          // primary: '#ff6a33', // #E53935
          // primary: '#0277bd', // #E53935
          primary: '#6E9887', 
          // secondary: colors.red.lighten4, // #FFCDD2
          // ...
          background: '#FFFFFF',
          surface: '#FFFFFF',
          'surface-bright': '#FFFFFF',
          'surface-light': '#EEEEEE',
          'surface-variant': '#424242',
          'on-surface-variant': '#EEEEEE',
          'primary-darken-1': '#1F5592',
          secondary: '#48A9A6',
          'secondary-darken-1': '#018786',
          error: '#B00020',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00',
        },

      },
    },
  },
})



// import { firestorePlugin } from "vuefire";
// import vuetify from "@/plugins/vuetify";
import './registerServiceWorker'

const app = createApp(App)
app.component("downloadExcel", JsonExcel);

app.use(router)
app.use(pinia)

app.use(auth0Instance);
app.use(VueFire, { firebaseApp })

app.use(vuetify)
// Install the authentication plugin here
// app.use(Auth0Plugin, {
//   domain,
//   clientId,
//   audience,
//   scope: 'openid profile email',
//   onRedirectCallback: appState => {
//     router.push(
//       appState && appState.targetUrl
//         ? appState.targetUrl
//         : window.location.pathname
//     );
//   }
// });
app.component('cncpt-btn', CncptBtn)
app.component('cncpt-text-field', CncptInput)
app.component('cncpt-basic-select', CncptBasicSelect)
app.component('cncpt-card-selector', CncptCardSelect)
app.component('cncpt-card-input', CncptCardInput)
app.component('cncpt-select-search', CncptSelectV2)
app.component('cncpt-datepicker', CncptDatepicker)
app.component('cncpt-card', CncptCardContainer)
app.component('cncpt-switch-card', CncptCardSwitch)
app.component('cncpt-slider-card', CncptSliderCard)
app.component('cncpt-combobox-card', CncptCardCombobox)


//Simple components
app.component('cncpt-switch', CncptSwitch)
app.component('cncpt-select', CncptSelect)
app.component('cncpt-textfield', CncptTextField)
app.component('cncpt-textarea', CncptTextArea)
app.component('cncpt-slider', CncptSlider)
app.component('cncpt-combobox', CncptCombobox)
// app.use(firestorePlugin);
app.mount('#app')
