import { getEmbeddableToken, adstxt, getIgnoredBids, getContainer, getSite, deploy } from "@/api/background-api";
// import { set, getAll, clear } from "@/cache/idb";
import { makeId } from "@/controllers/util";
import { getAuthorizationForUser } from "@/auth/auth.js";

import { defineStore } from 'pinia'
import { helperTexts } from "@/config/helperTexts";
import { setItem, getItem } from '@/firebase.js';
import { usePrebidStore } from "./prebid";
import { useUserStore } from "./user";
import { useAdminStore } from "./admin";
import { router } from "@/router/index.js";
import { useAdunitStore } from '@/stores/adUnits'
import { useSizesStore } from '@/stores/sizes'
import { useReportStore } from '@/stores/reporting'
import { cleanObject } from '@/controllers/util'

export const useMainStore = defineStore('main', {
    state: () => ({
        helperTexts,
        viewType: 0,
        deployChecks: {},
        viewTypeTable: 1,
        experimentCount: null,
        adunitCount: null,
        videoAdunitCount: null,
        adunitSelected: [],
        localBandDrawer: false,
        conceptxToken: null,
        timingToken: null,
        enhancedToken: null,
        adpayToken: null,
        conceptloadsToken: null,
        missingEntries: [],
        currentTxtFile: [],
        idToken: null,
        // mainColor: '#0277bd',
        // themeColor: '#0277bd',
        // themeSubColor: '#0C5A87',
        mainColor: '#6e9887',
        themeColor: '#6e9887',
        themeSubColor: '#6e9887',
        suggestionIgnoreList: [],
        deployTo: 'ADNUNTIUS',
        deployUrl: null,
        deployPrebidTo: 'AWS',
        implementationType: 'NORMAL',
        networkOverride: null,
        fetchedNetwork: null,
        env: "prod",

        site: null,
        sidebarItems: [{
            text: "Home", noId: true, link: "/", icon: "mdi-home", name: "Home",
            // subLinks: [
            //   { text: "Prebid overview" },
            // ]
        }],
        changesThatRequireDeploy: 0,
        loading: false,
        siteSettings: {},
        apiResponse: {
            show: false,
            error: null,
            msg: null,
        },
        localSiteData: {
            settings: {
                adUnits: []
            },
        },
        defaultValues: {
            customTargeting: {
                domain: 'DOMAIN_OF_SITE'
            },
            implementationType: 'NORMAL',
            ABTesters: [],
            settings: {

                fetchMarginPercent: 500,
                renderMarginPercent: 200,
                mobileScaling: 2.0,
                settingsProfiles: [],
                // globalRootMarginPercent: 250, -- DO NOT DEFAULT THIS
                bidderTargeting: [],
                mobileMultiplier: 2,
                prebidKeyMap: [],
                targetingToDMP: [],
                refreshInview: "0.25",
                prebidTimeOut: 1000,
                s2sTimeout: 500,
                // failsafe_timeout: 3000,
            },
        },
        reportTrackingAs: null,

    }),
    getters: {
        adunitBidders(state) {
            if (!state.normalizedAndDefaultedSite) return [];

            let uniqueBidders = []
            state.normalizedAndDefaultedSite.settings.adUnits.forEach((unit) => {
                let bids = unit.prebidBid
                if (Array.isArray(bids)) {
                    bids.forEach(bid => {
                        if (!uniqueBidders.includes(bid.bidder))
                            uniqueBidders.push(bid.bidder)
                    })

                }
            });
            return uniqueBidders
        },
        supplyPartnersUsed(state) {
            const allUsedBidders = state.adunitBidders
            const prebidStore = usePrebidStore()


            return prebidStore.supplyPartners.filter(bidder => {
                const checkForValue = bidder.alias || bidder.bidder
                return allUsedBidders.includes(checkForValue)
            })
        },
        adunitsPaths(state) {
            if (!state.normalizedAndDefaultedSite) return [];
            return state.normalizedAndDefaultedSite.settings.adUnits.map(
                (e) => e.path.split("/")[2]
            );
        },
        globalQueryParams() {
            let uri = window.location.search.substring(1);
            let params = new URLSearchParams(uri);
            return params;
        },
        selectedSiteName(state) {
            if (!state.site) return null
            return state.site.split("%20").join(" ");

        },
        selectedEnv(state) {
            let env = state.globalQueryParams.get("env");

            return env || state.env || "prod";

        },
        isConceptX(state) {
            return state.implementationType === 'CONCEPTX'
        },
        isCLEANROOM(state) {
            return state.implementationType === 'CLEANROOM'
        },
        sidIdFromtxt(state) {
            return state.sitetxt.filter(e => e.domainName === 'concept.dk')
        },
        normalizedAndDefaultedSite(state) {
            // important to not mutate state, so we take a deep copy
            let found = JSON.parse(JSON.stringify(state.localSiteData));
            if (!found) return null;

            if (!found.settings.prebidTimeOut)
                found.settings.prebidTimeOut = state.defaultValues.settings.prebidTimeOut;
            if (!found.settings.s2sTimeout)
                found.settings.s2sTimeout = state.defaultValues.settings.s2sTimeout;

            if (!found.settings.ABTesters)
                found.settings.ABTesters = state.defaultValues.ABTesters;

            if (!found.settings["customTargeting"])
                found.settings.customTargeting = state.defaultValues.customTargeting;


            if (!found.settings["implementationType"])
                found.settings.implementationType = found.settings.conceptXBidder ? 'CONCEPTX' : state.defaultValues.implementationType;


            // Debricated key
            const debricatedKeys = ['boosterConfig', 'boosterInserter', 'mediaTrustRef', 'useQuintesse', 'boosterIds', 'vitalsUrl']
            debricatedKeys.forEach(key => {
                if (found.settings[key]) {
                    delete found.settings[key]
                }
            })


            if (!Array.isArray(found.settings.videoAdunits)) {
                found.settings.videoAdunits = []
            }
            found.settings.videoAdunits = found.settings.videoAdunits.filter(videoUnit => {
                return Object.keys(videoUnit).length > 0
            })



            // This needs to be here for reactivity

            found.settings.adUnits.map((e) => {
                try {
                    if (typeof e.size === "string") {
                        e.size = JSON.parse(e.size) || false;
                    }
                    e.size = Array.isArray(e.size) && e.size.length === 0 ? [[[0, 0], []]] : e.size
                    e.targeting = e.targeting || {};
                    e.prebidBid = e.prebidBid || [];
                    e.prebidSizes =
                        typeof e.prebidSizes === "string"
                            ? JSON.parse(e.prebidSizes)
                            : e.prebidSizes || [];
                    // if (e.divIdOnPage === 'cncpt-dsk_lb1') {
                    //   console.log(e);

                    // }
                    return e;
                } catch (error) {
                    e.size = false;
                    console.error("parser error: ", error);
                }
            });

            if (found.settings.settingsProfiles) {
                found.settings.settingsProfiles = found.settings.settingsProfiles.map(profile => {
                    if (!Object.keys(profile.adunitsChanges).length !== found.settings.adUnits.length) {
                        found.settings.adUnits.forEach(unit => {
                            if (!profile.adunitsChanges[unit.id]) {
                                profile.adunitsChanges[unit.id] = {}
                            }
                        })
                    }
                    if (!profile.videoChanges) {
                        profile.videoChanges = {}
                    }
                    if (!Object.keys(profile.videoChanges).length !== found.settings.videoAdunits.length) {
                        found.settings.videoAdunits.forEach(unit => {
                            if (!profile.videoChanges[unit.id]) {
                                profile.videoChanges[unit.id] = {}
                            }
                        })
                    }
                    // we weill unpack and show save them as objects later, but for now its easier if they are strings
                    const profiles = profile.videoChanges

                    Object.keys(profiles).forEach(profileKey => {
                        Object.keys(profiles[profileKey]).forEach(profKey => {
                           
                            if (profKey === 'bids') {
                                if (profiles[profileKey][profKey].length > 0) {
                                    profiles[profileKey][profKey].forEach((videoChangeProp, index) => {
                                        if (typeof videoChangeProp === 'object') {
                                            profile.videoChanges[profileKey][profKey][index] = JSON.stringify(videoChangeProp)
                                        }
                                    })
                                }

                            }
                        })
                    })
                    return profile
                });
            }


            found.settings = {
                ...state.defaultValues.settings,
                ...found.settings,
            };
            return { ...found };
        },

        containerId(state) {
            if (!state.siteSettings) return null;
            return state.siteSettings.adnuntiusId;
        },
        globalAdunitPrefix(state) {
            if (!state.siteSettings) return false;
            return state.siteSettings.globalAdunitPrefix;
        },

        pageUrl(state) {
            if (!state.selectedSiteObject) return false;
            return state.selectedSiteObject.settings.page_url;
        },
        connectLink(state) {

            if (state.deployTo === 'OWNAWS') {
                return `${state.deployUrl}pubscript/${state.selectedSiteName}_${state.selectedEnv}.js`
            }
            if (state.deployTo === 'AWS') {
                return `https://cncptx.com/pubscript/${state.selectedSiteName}_${state.selectedEnv}.js`
            }
            if (
                !state.containerId ||
                !state.selectedEnv ||
                state.fetchedNetwork === false
            ) {
                return null;
            }
            let company = state.networkOverride || "concept_cph";


            return `https://tags.adnuntius.com/${company}/${state.containerId}.${state.selectedEnv}.js`;
        },
    },
    actions: {
        async getUserEmbeddableToken(site, type) {
            try {
                let token = (await getEmbeddableToken({ type, site })).token
                this[`${type}Token`] = token
            } catch (error) {
                console.log(error)
                return error
            }
        },
        REMOVE_TESTER(index) {
            this.localSiteData.settings.ABTesters.splice(index, 1);
        },
        CHANGE_TEST_SLIDER({ index, change, value, current }) {
            this.localSiteData.settings.ABTesters[index][change].weight = 100 - value;
            this.localSiteData.settings.ABTesters[index][current].weight = value;
        },



        ADD_TESTER(options) {
            if (!this.localSiteData.settings.ABTesters) {
                this.localSiteData.settings.ABTesters = []
            }
            this.localSiteData.settings.ABTesters.push(options)

        },
        UPDATE_TESTER({ index, options }) {
            this.localSiteData.settings.ABTesters[index] = options
        },
        SET_DRAWER(val) {
            this.localBandDrawer = val
        },
        SET_VIEWTYPE({ newIndex }) {

            this.viewType = newIndex;
        },
        SET_ADUNIT_INDEX({ newSelected }) {

            this.adunitSelected = newSelected;
        },

        SET_EXPERIMENT_COUNT(count) {
            this.experimentCount = count
        },
        SET_ADUNIT_COUNT(count) {
            this.adunitCount = count
        },
        SET_VIDEO_ADUNIT_COUNT(count) {
            this.videoAdunitCount = count
        },
        SET_VIEWTYPEFORTABLE({ viewTypeTable }) {
            this.viewTypeTable = viewTypeTable;
        },

        SET_REPORT_TRACKING(value) {
            this.reportTrackingAs = value
        },
        SET_DATA_FOR_CHECKS(value) {
            this.deployChecks = value
        },
        changeEnv(newEnv) {
            this.env = newEnv
        },
        INCREMENT_DEPLOY_CHANGES() {
            this.changesThatRequireDeploy = this.changesThatRequireDeploy + 1
        },
        RESET_DEPLOYCHANGES() {
            this.changesThatRequireDeploy = 0
        },
        setGlobalLoading(bool) {
            this.loading = bool
        },
        SET_IMPLEMENTATION_TYPE(type) {
            this.implementationType = type;

        },
        setAPIResponse({ error, msg }) {

            this.apiResponse.msg = msg;
            this.apiResponse.error = error;
            this.apiResponse.show = true;
            setTimeout(() => {
                this.apiResponse.show = false;
            }, 2000);
        },
        setIdToken(token) {
            this.idToken = token
        },
        SET_GLOBAL_PREFIX({ network }) {
            let findSite = this.localSiteData;
            if (findSite) {
                findSite.globalAdunitPrefix = network;
            }
        },
        setLocalSettings({ settings, site, document }) {
            if (!settings.settings.targetingToDMP) {
                settings.settings.targetingToDMP = this.defaultValues.settings.targetingToDMP;
            }
            this.localSiteData = settings
            this.deployTo = settings.deployInfo?.deployTo || 'ADNUNTIUS'
            this.deployUrl = settings.deployInfo?.deployUrl || null
            this.deployPrebidTo = settings.deployInfo?.deployPrebidTo || 'AWS'
            this.site = site;
            this.siteSettings = document

        },
        async getAdsTxt({ site }) {
            let { currentTxtFile, missingEntries } = await adstxt({ site });

            this.currentTxtFile = currentTxtFile
            this.missingEntries = missingEntries

        },
        async getSuggestionIgnores({ site }) {
            let ignoreList = await getIgnoredBids({ site });
            this.suggestionIgnoreList = ignoreList;
        },
        async FETCH_NETWORK_OVERRIDE({ newId }) {
            let containerId = newId;

            if (containerId) {
                let res = await getContainer({ containerId });

                let data = res.data;
                if (
                    data &&
                    data.getContainer &&
                    data.getContainer.data.networkOverride
                ) {
                    this.networkOverride = data.getContainer.data.networkOverride;

                } else {
                    this.networkOverride = null

                }
            } else {
                this.networkOverride = null
            }
            this.fetchedNetwork = true;

        },
        async SAVE_TO_FIREBASE() {

            let saveObj = this.siteSettings;
            saveObj.site = saveObj.site.split("%20").join(" ");
            setItem('siteSettings', this.selectedSiteName.split("%20").join(" "), saveObj)

        },
        async APP_INITIALIZATION({ idToken }) {
            try {

                const adunitStore = useAdunitStore()
                const adminStore = useAdminStore()
                const sizesStore = useSizesStore()
                const reportStore = useReportStore()
                const prebidStore = usePrebidStore()
                const userStore = useUserStore()
                this.setGlobalLoading(true)



                let auth = await getAuthorizationForUser(idToken);

                let userData = auth.user;

                const ISADMIN = userData.role === "admin";
                this.getUserEmbeddableToken(null, 'adpay')
      


                userStore.setUser({ auth })
                let allowedSites = userData.siteAccess;
                if (allowedSites.length === 0) { // for sites that want to access adpay (without having an central site)
                    // dispatch("LOG_OUT");
                    this.setGlobalLoading(false)

                    reportStore.LOAD_POPUPP()

                    return;
                }
                reportStore.LOAD_POPUPP()

                sizesStore.getAdSizes()
                adunitStore.getAllAdunitFormats()
                this.getUserEmbeddableToken(null, 'timing')
                if (ISADMIN) {

                    this.getUserEmbeddableToken(null, 'enhanced')
                    adminStore.loadAnomalies()
                    adminStore.loadPuplisherOpportnities()



                }
                let siteToGet = allowedSites[0]
                let { id } = router.currentRoute._value.params
                if (allowedSites.includes(id)) {
                    siteToGet = id
                }


                this.GET_DATA({ site: siteToGet })


                this.setGlobalLoading(false)

                prebidStore.GET_PBJS_VERSIONS()




            } catch (error) {
                this.setGlobalLoading(false)

                console.log("something wrong here", error);
                return Promise.reject(error);
            }
        },
        async GET_DATA({ site, env = 'prod' }) {
            this.conceptxToken = null
            this.conceptloadsToken = null
            this.deployPrebidTo = 'AWS'
            const prebidStore = usePrebidStore()
            const userStore = useUserStore()
            this.setGlobalLoading(true)
            if (userStore.isAdmin) {
                this.getSuggestionIgnores({ site })
            }
            prebidStore.GET_SUPPLY_PARTNERS({ site })
            this.SET_ADUNIT_INDEX({ newSelected: [] })

            let siteSettingsProm = getSite({ site, env });
            // let txtProm = adstxt({ site });
            this.getAdsTxt({ site })
            this.getUserEmbeddableToken(site, 'conceptloads')


            const querySnapshot = getItem('siteSettings', site.split("%20").join(" "))

            let [siteSettings, firebaseResponse] = await Promise.all([siteSettingsProm, querySnapshot])


            const document = firebaseResponse.data();
            document.anomalyParams = {
                allowUpwardAlerts: false,
                cncptLoadPeriod: 24,
                cncptSeverityLimit: 0.95,
                impRevLoadPeriod: 7,
                impRevSeverityLimit: 0.95,
                ...document.anomalyParams
            }
            document.addPbjsModules = document.addPbjsModules || []
            document.removePbjsModules = document.removePbjsModules || []
            document.versionLock = document.versionLock || null

            if (siteSettings?.deployInfo?.deployTo !== 'AWS') {
                this.FETCH_NETWORK_OVERRIDE({ newId: document.adnuntiusId })
            }

            document.adstxtGroups = document.adstxtGroups || []
            document.enabledBidders = document?.enabledBidders?.filter(e => Boolean(e)) || []
            delete document.settings

            if (siteSettings.settings?.videoAdunits) {
                siteSettings.settings.videoAdunits
                    .filter(e => Object.keys(e).length > 0)
                    .map(e => {
                        if (!e.id) {
                            e.id = makeId(7)
                        }
                        if (!e.videoPlayer) {
                            e.videoPlayer = 2
                        }
                        if (!e.targeting) {
                            e.targeting = {}
                        }
                        if (!e.mediaTypes) {
                            e.mediaTypes = {
                                video: {
                                    linearity: 1,
                                    maxduration: 30,
                                    context: "instream",
                                    playerSize: [640, 480],
                                    api: [2],
                                    protocols: [3, 6, 7, 8],
                                    mimes: ["video/mp4", "video/webm"],
                                },
                            }
                        }
                        if (e.bids) {

                            e.bids = e.bids.map(bid => {
                                if (typeof bid === 'object') {
                                    return JSON.stringify(bid, null, 2)

                                }
                                return bid
                            })
                        }
                        return e
                    })

            }


            // state.localSiteData = siteSettings
            if (siteSettings.implementationType === "CONCEPTX") {
                this.getUserEmbeddableToken(site, 'conceptx')

            }
            this.setLocalSettings({ settings: siteSettings, site, document })

            if (userStore.isAdmin) {
                // dispatch('INIT_XPAY')
                const adminStore = useAdminStore()
                adminStore.getEmbeddableToken(site, 'xpay')

            }
            this.setGlobalLoading(false)


        },
        async SAVE_AND_DEPLOY({ context, selectedSiteObject }) {
            try {
                const prebidStore = usePrebidStore()
                const userStore = useUserStore()

                this.setGlobalLoading(true)

                this.SAVE_TO_FIREBASE()

                const deployTo = this.deployTo;
                const { site, adnuntiusId } = this.siteSettings;
                const { settings } = selectedSiteObject;
                if (settings.usePrebid || settings.implementationType === "CONCEPTX") {
                    let videoAliases = []

                    const videoUnits = settings.videoAdunits

                    if (videoUnits && videoUnits.length > 0) {
                        const parsedVideoUnits = videoUnits
                            .map(e => {
                                e.bids = e.bids.map(bid => {
                                    let theBid = bid
                                    while (typeof theBid === 'string') {
                                        theBid = JSON.parse(theBid)
                                    }
                                    return theBid
                                })
                                return e
                            })


                        let aliasesForVideo = parsedVideoUnits.map(vUnit => vUnit.bids).flat(Infinity).filter(bid => bid.aliasFor)
                        let alreadyThere = []
                        let uniqueAliases = aliasesForVideo
                            .map(bid => [bid.bidder, bid.aliasFor])
                            .filter(alias => {
                                if (!alreadyThere.includes(alias[0])) {
                                    alreadyThere.push(alias[0])
                                    return true
                                }
                            })




                        videoAliases = uniqueAliases
                        settings.videoAdunits = parsedVideoUnits
                    }

                    const aliases = prebidStore.aliases //.snapshot.docs.map(doc => doc.data());
                    const adunitBidders = this.adunitBidders //.map(e => e.bidder)



                    const aliasesUsed = aliases.filter(alias => adunitBidders.includes(alias.alias))

                    const supplyPartners = this.supplyPartnersUsed

                    // .filter(partner => partner.asi && partner.sid)
                    // .map(partner => ({ asi: partner.asi, sid: partner.sid }))
                    settings.supplyPartners = supplyPartners.map(sp => {
                        let { gvlid, bidder, asi, mainAdapter, s2sApproved, name, bidCpmAdjustment, sid, alias, extraNodes } = sp
                        return { gvlid, bidder, asi, mainAdapter, s2sApproved, name, bidCpmAdjustment, sid, alias, extraNodes }
                    })
                    if (settings.settingsProfiles) {
                        settings.settingsProfiles = settings.settingsProfiles.map(profile => {
                            if (profile.adunitsArray) {
                                profile.adunitsChanges = profile.adunitsArray.reduce((accumulator, currentValue) => {
                                    accumulator[currentValue.id] = cleanObject(currentValue)
                                    return accumulator

                                }, {})
                                delete profile.adunitsArray
                            }
                            if (profile.videoArray) {
                                profile.videoChanges = profile.videoArray.reduce((accumulator, currentValue) => {
                                    const cleanAndFixedForStrings = cleanObject(currentValue)


                                    accumulator[currentValue.id] = cleanAndFixedForStrings
                                    return accumulator

                                }, {})
                                delete profile.videoArray
                            }
                            if (profile.videoChanges) {
                                // we unpack and show them as string, so now we need to do the reverse and save as JSON
                                Object.keys(profile.videoChanges).forEach(videoKey => {
                                    const pfile = profile.videoChanges[videoKey]
                                    Object.keys(pfile).forEach(profKey => {
                                        if (profKey === 'bids') {
                                            if (pfile[profKey].length > 0) {
                                                pfile[profKey].forEach((videoChangeProp, index) => {
                                                    if (typeof videoChangeProp === 'string') {
                                                        profile.videoChanges[videoKey][profKey][index] = JSON.parse(videoChangeProp)
                                                    }
                                                })
                                            }

                                        }
                                    })
                                })
                            }
                            return profile
                        });
                    }
                    settings.prebidAliases = [...aliasesUsed.map(e => [e.alias, e.bidder, e.gvlid]), ...videoAliases]


                }

                console.log({ settings });
                let deployObject = {
                    site,
                    adnuntiusId,
                    context,
                    deployTo,
                    settings: JSON.parse(JSON.stringify(settings)),
                    deployer: userStore.userEmail,
                }

                console.log({ deployObject });


                if (context.env) {
                    // console.log(deploy);

                    await deploy(deployObject);
                    this.RESET_DEPLOYCHANGES()
                } else {
                    console.log('MISSING ENV');

                }

                this.setGlobalLoading(false)

            } catch (error) {
                console.error("error: ", error);
                this.setGlobalLoading(false)
            }
        }
    }

})